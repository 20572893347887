<template>
  <div class="about">
    <h1>GENERADOR EN TIEMPO REAL </h1>

    <div class="row justify-content-md-center">
        <div class="col-lg-5 p-4 card" style="margin-right:10px">
          <h2> TUS DATOS</h2>

            <div class="row p-4">
                      <div class="col-lg-4 p-1">
                        <h3 style="font-size:12px; text-align:left">Nombre</h3>
                      </div>
                      <div class="col-lg-8 p-1">
                        <input type="text" class="form-control" name="name" v-model="name">
                      </div>

                      <hr>

                      <div class="col-lg-4 p-1">
                        <h3 style="font-size:12px; text-align:left">Apellido</h3>
                      </div>
                      <div class="col-lg-8 p-1">
                        <input type="text" class="form-control" name="lastname" v-model="lastname">
                      </div>

                         <hr>
                      <div class="col-lg-4 p-1">
                        <h3 style="font-size:12px; text-align:left">Cargo</h3>
                      </div>
                      <div class="col-lg-8 p-1">
                        <input type="text" class="form-control" name="role" v-model="role">
                      </div>
                     
                      <hr>

                      <div class="col-lg-4 p-1">
                        <h3 style="font-size:12px; text-align:left">Correo Corporativo</h3>
                      </div>
                      <div class="col-lg-8 p-1">
                        <input type="text" class="form-control" name="mail" v-model="mail">
                      </div>

                      <hr>
                      <div class="col-lg-4 p-1">
                        <h3 style="font-size:12px; text-align:left">Telefono</h3>
                      </div>
                      <div class="col-lg-8 p-1">
                        <input type="text" class="form-control" name="phone" v-model="phone">
                      </div>
                      <hr>
                         <div class="col-lg-4 p-1">
                        <h3 style="font-size:12px; text-align:left">Telefono 2</h3>
                      </div>
                      <div class="col-lg-8 p-1">
                        <input type="text" class="form-control" name="phone2" v-model="phone2">
                      </div>


                    
                     

            </div>

        </div>


        <div class="col-lg-5 card p-4">

           <h3>VISTA PREVIA DE LA FIRMA</h3>

<br>
<br>



          <table style="width:600px; border:0px;" ref="printcontent">
                    <link rel="preconnect" href="https://fonts.googleapis.com">
                      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                      <link href="https://fonts.googleapis.com/css2?family=Raleway&display=swap" rel="stylesheet">
                      <link rel="preconnect" href="https://fonts.googleapis.com">
                      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                      <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap" rel="stylesheet">
                      <link rel="preconnect" href="https://fonts.googleapis.com">
                      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                      <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@500;700&display=swap" rel="stylesheet">
                    

                      <tr style="border:0px;">
                        <td style="border:0px;"> 
                          <img src="logo_evoluciona_firma.png"  width="220px" height="auto" alt="Logo" >
                          
                          </td>
                        <td style="border:0px;">
                          <p style="font-family: 'Quicksand; font-weight:800; color: #1D519D; font-size:17px; padding:0px; margin:0; text-align:left">{{name}} {{lastname}}</p>
                          <p style="font-family: 'Quicksand; font-weight:600; color: #D51492; font-size:12px; padding:0px; margin:0; text-align:left">{{role}}</p>
                          <p style="font-family: 'Quicksand; font-weight:400; color: #1D519D; font-size:11px; padding:0px; margin:0; text-align:left">{{mail}}</p>
                          <p style="font-family: 'Quicksand; font-weight:400; color: #1D519D; font-size:11px; padding:0px; margin:0; text-align:left">{{phone}} <span v-if="phone2 != ''"> | </span> {{phone2}}</p>
                          <p style="font-family: 'Quicksand; font-weight:400; color: #1D519D; font-size:11px; padding:0px; margin:0; text-align:left">Av. Pdte. Riesco 5561 of. 1804 Las Condes</p>
                          <p style="text-align:left">
                          <a href="https://www.facebook.com/Hipotecaria-Evoluciona-103666368493835"><img src="social3.png" width="16px" style="width:16px; margin-right:3px" alt="Sociales"></a>
                          <a href="https://www.linkedin.com/company/hipotecaria-evoluciona/"><img src="social2.png" width="16px" style="width:16px; margin-right:3px" alt="Sociales"></a>
                          <a href="https://www.instagram.com/hipotecariaevoluciona/"><img src="social1.png" width="16px" style="width:16px; margin-right:3px" alt="Sociales"></a> <br>
                          <a href="https://www.hipotecariaevoluciona.cl"><img src="webs.png" width="150px"  alt="Sociales"></a></p>
                        </td>
                    </tr>             
          </table>


<hr>

 <button @click="printThis" class="btn btn-info text-white">GENERAR FIRMA EN IMAGEN</button>





        </div>
        
        <div class="alert alert-primary col-lg-4 mt-4" role="alert" >
        <strong>GUIA RAPIDA DE COMO COPIAR LA FIRMA</strong> <hr>
        <strong> Alternativa 1</strong> <br><span style="font-size:11px">  Seleciona todo desde la derecha hacia la izquierda con el mouse,  luego ctrl + c  y en el lugar donde colocas tu firma colocar
        ctrl + v, con esto se pegara ese diseño en tu firma. </span><hr>
        <strong> Alternativa 2</strong> <br><span style="font-size:11px">  Seleccionar todo y tomas con el mouse y lo arrastras hasta el lugar donde colocaras la firma
        </span><br>
        <hr>
        <strong> Alternativa 3</strong> <br><span style="font-size:11px"> Haz clic en generar firma y automaticamente se va crear un archivo de imagen el cual puedes subir en tu firma en cualquier plataforma de correo.
        </span><br>
        </div>



<div class="col-lg-12"></div>

  <div class="col-lg-6">
    <h3>¿Como Configurar firma en GMAIL?</h3>
    <iframe src="https://player.vimeo.com/video/596548260?h=0b3641f3ff&title=0&byline=0&portrait=0" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
  </div>
  <div class="col-lg-6">
    <h3>¿Como Configurar firma en OUTLOOK?</h3>
   <iframe src="https://player.vimeo.com/video/596617958?h=c3393c9826&title=0&byline=0&portrait=0" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
  </div>



    </div>

  </div>
</template>

<script>
import html2canvas from 'html2canvas';
export default

{
  
 data: function () {
    return {
      name: 'Olivia',
      lastname: 'Sarabia Garbarini',
      phone: '+56 2 3324 1111',
      role: 'Encargada Experiencia Cliente',
      mail: 'osg@hipotecariaevoluciona.cl',
      phone2: '+56 9 5555 5555',
      

    }
  },
  methods: {
    async printThis() {
      console.log("printing..");
      const el = this.$refs.printcontent;

      const options = {
        type: "dataURL",
      };
      const printCanvas = await html2canvas(el, options);

      const link = document.createElement("a");
      link.setAttribute("download", "download.png");
      link.setAttribute(
        "href",
        printCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();

      console.log("done");
    },
  },

  
}
</script>
<style>
.about {
  background-color: #fff;
  padding: 1%;
}
</style>
